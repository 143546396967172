



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BurgerIcon from '@/components/BurgerIcon.vue';
import SlideMenu from '@/components/SlideMenu.vue';

@Component({
    components: { SlideMenu, BurgerIcon },
})
export default class AproconHeader extends Vue {

    @Prop({ default: false })
    public readonly nologo!: boolean|string|undefined;

    @Prop({ default: require('@/assets/images/aprocon-logo.png') })
    public readonly logo!: string;

    @Prop({ default: require('@/assets/images/aprocon-logo-small.png') })
    public readonly logoMobile!: string;

    @Prop({ default: null })
    public readonly title!: string|null;

    private showMenu: boolean = false;

    private get headerTitle(): string {
        if (this.title) {
            return this.title;
        }
        // we dont know if a router exists, so check if $route is defined
        const instance = this as any;
        if (instance.$route && instance.$route.meta && instance.$route.meta.headerTitle) {
            return instance.$route.meta.headerTitle;
        }
        return document.title;
    }

    public close() {
        this.showMenu = false;
    }

    @Watch('showMenu')
    private onShowMenuChanged() {
        if (this.showMenu) {
            this.$emit('open');
        } else {
            this.$emit('close');
        }
    }
}
