










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BurgerIcon extends Vue {

    @Prop({ default: false })
    public readonly open!: boolean;
}
