











import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class SlideMenu extends Vue {

    @Prop({ default: false })
    public readonly open!: boolean;

    public created() {
        window.addEventListener('resize', this.closeOnBreakpoint, { passive: true });
        // window.addEventListener('scroll', this.close, { passive: true });
    }

    public destroyed() {
        window.removeEventListener('resize', this.closeOnBreakpoint);
        // window.removeEventListener('scroll', this.close);
    }

    /**
     * Close the menu only when we are switching from mobile/tablet to desktop width
     */
    private closeOnBreakpoint() {
        if (window.innerWidth >= 960) {
            this.close();
        }
    }

    private close() {
        this.$emit('update:open', false);
    }

    // @Watch('open')
    // private onOpenStateChanged() {
    //     if (this.open) {
    //         document.querySelector('html')!.style.overflowY = 'hidden';
    //     } else {
    //         document.querySelector('html')!.style.overflowY = 'unset';
    //     }
    // }
}

